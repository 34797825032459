import React, { Component } from 'react';

class Contacts extends Component {
    constructor() {
        super();
        this.state = {
            name: '',
            phone: '',
            organization: '',
            email: '',
            subject: '',
            message: ''
        };

        this.handleChange = this.handleChange.bind(this);
    }
    
    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    Send = async (e) => {
        e.preventDefault();

        // Проверка обязательных полей
        if (!this.state.name.trim() || !this.state.phone.trim()) {
            alert('Пожалуйста, заполните обязательные поля: Имя и Номер телефона.');
            return;
        }

        const chat_id = '-1001527659594';
        const textValue = `
Имя: ${this.state.name} <br/>
Организация: ${this.state.organization} <br/>
Телефон: ${this.state.phone} <br/>
Почта: ${this.state.email} <br/>
Тема: ${this.state.subject} <br/>
Сообщение: ${this.state.message}
        `;

        const requestOptionsPush = {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ chat_id, text: textValue })
        };

        let data = null;
        const res = await fetch(
            'https://api.telegram.org/bot1789973923:AAHqyzLPjeCl51Nk0qzHKS0MOwEh4_on7jQ/sendMessage',
            requestOptionsPush
        );

        try {
            data = await res.json();
        } catch {
            data = await res.text();
        }

        if (res.ok) {
            console.log(data);
            alert('Заявка успешно отправлена');
        } else {
            console.log(data);
            alert('Что-то пошло не так, попробуйте позже');
        }
    };

    render() {
        return (
            <section className="contact_info_area sec_pad bg_color">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="contact_info_item">
                                <h6 className="f_p f_size_20 t_color3 f_500 mb_20">Адрес</h6>
                                <p className="f_400 f_size_15">
                                660001, Красноярский край, г. Красноярск, ул. Ладо Кецховели, д. 22А, оф.12-29
                                </p>
                            </div>
                            <div className="contact_info_item">
                                <h6 className="f_p f_size_20 t_color3 f_500 mb_20">Контактные данные</h6>
                                <p className="f_400 f_size_15">
                                    <span className="f_400 t_color3">Отдел продаж:</span>{' '}
                                    <a href="tel:79048932526">+7 904 893-25-26</a>
                                </p>
                                <p className="f_400 f_size_15">
                                    <span className="f_400 t_color3">Техническая поддержка: </span>{' '}
                                    <a href="tel:79048932526">+7 904 893-25-26</a>
                                </p>
                                <p className="f_400 f_size_15">
                                    <span className="f_400 t_color3">Электронная почта:</span>{' '}
                                    <a href="mailto:mail@rts-iot.ru">mail@rts-iot.ru</a>
                                </p>
                            </div>
                        </div>

                        <div className="contact_form col-lg-9">
                            <h2 className="f_p f_size_22 t_color3 f_600 l_height28 mb_40">
                                Оставить заявку
                            </h2>
                            <form
                                onSubmit={this.Send}
                                className="contact_form_box"
                                method="post"
                                id="contactForm"
                            >
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="form-group text_box">
                                            <input
                                                type="text"
                                                id="name"
                                                name="name"
                                                placeholder="Ваше имя (обязательно)"
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className="form-group text_box">
                                            <input
                                                type="text"
                                                name="phone"
                                                id="phone"
                                                placeholder="Номер телефона (обязательно)"
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-12">
                                        <div className="form-group text_box">
                                            <input
                                                type="text"
                                                id="organization"
                                                name="organization"
                                                placeholder="Ваша организация"
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className="form-group text_box">
                                            <input
                                                type="text"
                                                name="email"
                                                id="email"
                                                placeholder="Электронная почта"
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className="form-group text_box">
                                            <input
                                                type="text"
                                                id="subject"
                                                name="subject"
                                                placeholder="Ваш запрос"
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-12">
                                        <div className="form-group text_box">
                                            <textarea
                                                onChange={this.handleChange}
                                                name="message"
                                                id="message"
                                                cols="30"
                                                rows="10"
                                                placeholder="Интересующая вас информация..."
                                            />
                                        </div>
                                    </div>
                                </div>
                                <button type="submit" className="btn_three">
                                    Оставить заявку
                                </button>
                            </form>
                            <div id="success">Ваше сообщение успешно отправлено!</div>
                            <div id="error">
                                Что-то пошло не так. Попробуйте воспользоваться сервисом позднее.
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Contacts;
