import React from 'react';
import Sectitle from "./Title/Sectitle";
import ErpanalyticsItem from "./ErpanalyticsItem";

const Erpanalytics =()=>{
    return(
        <section className="erp_analytics_area">
            <div className="container">
                <Sectitle Title="Как мы работаем" TitleP="Мы практикуем комплексный подход к решению поставленных задач, используем передовые и надежные технологии."
                 sClass="hosting_title erp_title text-center"/>
                <div className="row">
                    <ErpanalyticsItem analyticsImage="settings1.png" text="Проводим предпроектное обследование."/>
                    <ErpanalyticsItem analyticsImage="cloud-computing.png" text="Проектируем решение"/>
                    <ErpanalyticsItem analyticsImage="growth.png" text="Внедряем и передаем в эксплуатацию."/>
                </div>
            </div>
        </section>
    )
}
export default Erpanalytics;